import { BiLock } from 'react-icons/bi'
import CardForm from './forms/Card'
import CheckoutFooter from './footer'
import { motion } from 'framer-motion'
import { useStateValue } from '../../context/StateProvider'
import {
  emptyCart,
  calculateCartTotal,
  getCartTotal,
} from '../../utils/functions'
import { useState, useRef } from 'react'
import { ImSpinner3 } from 'react-icons/im'
import { toast } from 'react-toastify'
import { orderService } from '../../service'
import { useNavigate } from 'react-router'

const Body = ({ action }) => {
  const [loading, setLoading] = useState(false)
  const userNameRef = useRef(null)
  const mobileRef = useRef(null)
  const navigate = useNavigate()

  const [{ cartTotal, cartItems, tableNumber }, dispatch] = useStateValue()

  const placeOrder = () => {
    if (!cartItems || cartItems?.length == 0) return

    const products = cartItems?.map((item) => {
      return {
        productId: item.pid,
        options: Object.entries(item.options)?.map(([groupId, plist]) => ({
          groupId,
          selectedOptionId: plist?.map((p) => p.id),
        })),
        quantity: item.quantity,
        instruction: item?.instruction,
      }
    })
    const totalPrice = getCartTotal(cartItems)
    const readyData = {
      products,
      tableNumber: Number(tableNumber),
      totalPrice,
      userName: userNameRef?.current?.value,
      userMobileNumber: mobileRef?.current?.value,
    }

    setLoading(true)
    orderService
      .addOne(readyData)
      .then(async (order) => {
        toast.success('Order succeeded!', {
          position: 'top-center',
        })
        setLoading(false)
        await emptyCart(cartItems, dispatch)
        action(false)
        navigate(`/order/${order.orderId}`)
      })
      .catch((_) => {
        toast.error('Order failed!', {
          position: 'top-center',
        })
      })
  }

  return (
    <div className="w-full h-full rounded-t-[2rem]  bg-cartBg flex flex-col">
      <div className="min-h-[50vh] mt-5">
        <CardForm userNameRef={userNameRef} mobileRef={mobileRef} />
        <div className="w-full flex items-center justify-center my-2">
          <p className="text-gray-300">
            Amount Due:{' '}
            <span className="font-bold text-white">৳{cartTotal}</span>{' '}
          </p>
        </div>

        <div className="w-full flex items-center justify-center mt-4">
          <motion.button
            onClick={placeOrder}
            whileTap={{ scale: 0.95 }}
            className="flex items-center justify-center gap-2 w-[90%] p-2 rounded-full bg-gradient-to-tr from-accent to-accent-2 hover:from-accent hover:to-accent-2 transition-all duration-75 ease-in-out text-gray-50 text-lg my-2 hover:shadow-lg"
          >
            {!loading ? (
              'Confirm Order'
            ) : (
              <ImSpinner3 className="animate animate-spin" />
            )}
          </motion.button>
        </div>
      </div>
      <CheckoutFooter />
    </div>
  )
}

export default Body
