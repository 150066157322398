import { motion } from 'framer-motion'
import { useStateValue } from '../../context/StateProvider'

const CartTotal = ({ checkoutState }) => {
  const [{ cartTotal }] = useStateValue()
  return (
    <div className="w-full mt-2 md:mt-0 flex-1 rounded bg-cartItem rounded-t-[2rem] px-8 py-2 flex flex-col items-center gap-4 pt-6 pb-4">
      <div className="w-full flex items-center justify-between">
        <p className="text-gray-400 text-base">Sub Total</p>
        <p className="text-gray-400 text-base">
          <span className="text-sm text-accent">৳</span> {cartTotal}
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
        <p className="text-gray-400 text-base">Delivery</p>
        <p className="text-gray-400 text-base">
          <span className="text-sm text-accent">৳</span> {0.0}
        </p>
      </div>
      <div className="w-full border-b border-gray-600 my-"></div>
      <div className="w-full flex items-center justify-between">
        <p className="text-gray-50 text-base uppercase">Total</p>
        <p className="text-gray-50 text-base">
          <span className="text-sm text-accent">৳</span> {cartTotal}
        </p>
      </div>
      <motion.button
        onClick={() => checkoutState(true)}
        whileTap={{ scale: 0.8 }}
        className="w-full p-2 rounded-full bg-gradient-to-tr from-accent to-accent-2 text-gray-50 text-lg my-2 hover:shadow-lg"
      >
        Checkout ৳{cartTotal}
      </motion.button>
    </div>
  )
}

export default CartTotal
