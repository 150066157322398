import axios from 'axios'
import Cookies from 'js-cookie'

const apliBaseUrl = process.env.REACT_APP_API
const token = Cookies.get('token')

const API = axios.create({
  timeout: 20000,
  baseURL: apliBaseUrl,
})

API.interceptors.request.use(
  function (config) {
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

export default API
