import React from 'react'
import EmptyCartImg from '../Assets/empty.png'

const EmptyCart = () => {
  return (
    <div className="w-full p-5 flex flex-col items-center gap-4 justify-center">
      <img className="h-20" src={EmptyCartImg} alt="empty cart" />
      <p className="text-textColor  font-semibold">Cart is empty</p>
    </div>
  )
}

export default EmptyCart
