import 'react-toastify/dist/ReactToastify.css'
import './App.css'

import HomePage from './Pages/HomePage'
import MenuPage from './Pages/MenuPage'
import OrderPage from './Pages/OrderPage'
import { AnimatePresence } from 'framer-motion'
import { ToastContainer } from 'react-toastify'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import {
  calculateCartTotal,
  dispatchUsers,
  fetchFoodData,
  fetchUserCartData,
  isAdmin,
  dispatchShopId,
  dispatchTableNumber,
} from './utils/functions'
import { useEffect } from 'react'
import { useStateValue } from './context/StateProvider'
import Cart from './Components/Cart/index'

function App() {
  const [
    { showCart, showContactForm, user, foodItems, cartItems, adminMode },
    dispatch,
  ] = useStateValue()
  const [query] = useSearchParams()
  const shopId = query.get('m')
  const tableNumber = query.get('t')

  useEffect(() => {
    foodItems &&
      cartItems.length > 0 &&
      calculateCartTotal(cartItems, foodItems, dispatch)
  }, [cartItems, foodItems, dispatch])

  useEffect(() => {
    console.log('shopId', shopId)
    if (shopId || tableNumber) {
      console.log('shopId inside', shopId)
      dispatchShopId(dispatch, shopId)
      dispatchTableNumber(dispatch, tableNumber)
    }
  }, [dispatch])

  return (
    <AnimatePresence mode="wait">
      <>
        <ToastContainer autoClose={800} />
        <div className="w-screen h-auto min-h-[100vh] flex flex-col">
          {showCart && <Cart />}
          <main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/menu" element={<MenuPage />} />
              <Route path="/order/:orderId" element={<OrderPage />} />
              {/* <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/about" element={<About />} />
            
            <Route path="/services" element={<Services />} /> */}
            </Routes>
          </main>
        </div>
      </>
    </AnimatePresence>
  )
}

export default App
