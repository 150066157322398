/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../Assets/Logo.svg'
import { BsCart2 } from 'react-icons/bs'
import { HiOutlineBars3 } from 'react-icons/hi2'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import HomeIcon from '@mui/icons-material/Home'
import InfoIcon from '@mui/icons-material/Info'
import CommentRoundedIcon from '@mui/icons-material/CommentRounded'
import PhoneRoundedIcon from '@mui/icons-material/PhoneRounded'
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded'
import { toggleCart as toggle } from '../utils/functions'
import { useStateValue } from '../context/StateProvider'

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const [{ showCart }, dispatch] = useStateValue()
  const menuOptions = [
    {
      text: 'Home',
      link: '/',
      icon: <HomeIcon />,
    },
    {
      text: 'Menu',
      link: '/menu',
      icon: <CommentRoundedIcon />,
    },
    {
      text: 'About',
      icon: <InfoIcon />,
    },
    {
      text: 'Contact',
      icon: <PhoneRoundedIcon />,
    },
  ]
  const toggleCart = () => {
    toggle(dispatch, showCart)
  }

  return (
    <nav className="flex items-center justify-between relative z-10">
      <div className="nav-logo-container height-auto flex items-center">
        <Link to="/">
          <img src={Logo} alt="" className="h-10 md:h-16" />
        </Link>
      </div>
      <div className="navbar-links-container hidden md:flex flex items-center gap-4 justify-end flex-grow">
        <div className="flex items-center">
          <div className="flex items-center">
            {menuOptions.map((menu) => {
              return (
                <Link to={menu.link} key={menu.link}>
                  {menu.text}
                </Link>
              )
            })}
          </div>
          <CartButton onClick={toggleCart} />
        </div>
        <button className="primary-button">Book Now</button>
      </div>

      <div className="flex gap-4 items-center">
        <div className="block sm:hidden">
          <CartButton onClick={toggleCart} />
        </div>
        <div className="navbar-menu-container">
          <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
      </div>

      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
          className="pt-6 pl-6 w-[250px]"
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding key={item.text}>
                <ListItemButton>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <Link to={item.link}>
                    <ListItemText primary={item.text} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </nav>
  )
}

export default Navbar

const CartButton = ({ onClick }) => {
  const [{ cartItems }] = useStateValue()

  return (
    <button onClick={onClick}>
      <span className="border border-white text-accent inline-block rounded-full p-2 flex text-xl text-bold justify-center items-center w-9 h-9 relative">
        {cartItems.length ? (
          <span className="text-accent-2 absolute text-sm right-0 top-[-2px]">
            {cartItems.length}
          </span>
        ) : undefined}
        <BsCart2 className="navbar-cart-icon text-color-accent font-bold" />
      </span>
    </button>
  )
}
