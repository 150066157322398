import { BsShieldLock } from 'react-icons/bs'

const CheckoutFooter = () => {
  return (
    <div className="w-full flex-1 mt-2 md:mt-0 rounded bg-cartItem rounded-t-[2rem] px-8 py-2 flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="text-white">
          Thank <span className="font-bold text-accent-2">YOU</span>
        </p>
        <p className="text-gray-400 text-xs">Estimated wait time: 20 mins</p>
      </div>
    </div>
  )
}

export default CheckoutFooter
