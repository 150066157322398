import React, { useEffect, useState } from 'react'
import { useLayoutEffect, useRef } from 'react'
import PickMeals from '../Assets/order-food.png'
import ChooseMeals from '../Assets/choose-image.png'
import DeliveryMeals from '../Assets/trolley.png'
import {
  GiFruitTree,
  GiChickenOven,
  GiBeerBottle,
  GiBowlOfRice,
} from 'react-icons/gi'
import { MdOutlineIcecream } from 'react-icons/md'
import { FaFish, FaList } from 'react-icons/fa'
import FilterButton from './FilterButton'
import NotFound from './NotFound'
import Loader from './Loader'
import FoodItem from './FoodItem'
import { motion } from 'framer-motion'
import { orderService } from '../service'
import { useStateValue } from '../context/StateProvider'
import Details from './Details/index'
import { toast } from 'react-toastify'
import { useParams } from 'react-router'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'

const Order = () => {
  const [filter, setFilter] = useState('all')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const parentRef = useRef(null)
  const containerRef = useRef(null)
  const [{ shopId }, dispatch] = useStateValue()

  const { orderId } = useParams()

  useEffect(() => {
    setLoading(true)
    orderService.getOne(orderId).then((data) => {
      setLoading(false)
      setData(data)
    })
  }, [orderId])

  return (
    <>
      <div className="pt-6 lg:pt-16">
        <div className="work-section-top">
          <p className="primary-subheading">Track</p>
          <h1 className="primary-heading">Your Order</h1>
          <h3 className="text-lg font-semibold text-gray-400">
            Order Details:{' '}
            <span className="text-accent-2">#{orderId?.split('-')[0]}</span>
          </h3>
        </div>

        <div className="mb-12">
          <div className="my-8">
            <DotLottieReact
              src="https://lottie.host/93addd97-545c-4507-ac9e-2a5d68af18a2/cxl1kLXFEc.lottie"
              loop
              autoplay
            />
          </div>
          <div>
            <section className="py-8 relative">
              <div className="w-full max-w-7xl px-4 md:px-5 lg:px-5 mx-auto">
                <div className="w-full flex-col justify-start items-start lg:gap-10 gap-8 inline-flex">
                  <div className="w-full flex-col justify-center sm:items-center items-start gap-8 flex">
                    <ol className="flex flex-col sm:flex-row sm:items-center items-start w-full sm:gap-0 gap-3">
                      <li className="flex w-full relative justify-center text--600 text-base font-semibold after:content-['']x  sm:after:w-full after:h-0.5 sm:after:border after:border-dashed after:bg-accent after:none sm:after:inline-blockx after:absolute lg:after:top-5 after:top-3 xl:after:left-52 lg:after:left-48 md:after:left-36 sm:after:left-28 after:left-20">
                        <div className="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                          <span className="w-6 h-6 bg-accent text-center border-2 border-transparent rounded-full flex justify-center items-center mx-auto mb-1 text-base font-bold text-white lg:w-10 lg:h-10">
                            1
                          </span>
                          Order Confirmed <br />
                          <span className="text-gray-500 text-base font-normal text-center">
                            {new Date()
                              .toLocaleString()
                              ?.split(':')
                              ?.slice(0, 2)
                              ?.join(':')}
                          </span>
                        </div>
                      </li>
                      <li className="flex w-full relative justify-center text-black text-base font-semibold sm:after:content-['']  sm:after:w-full after:h-0.5 after:border after:border-dashed after:bg--200 after:inline-block after:absolute lg:after:top-5 after:top-3 xl:after:left-52 lg:after:left-48 md:after:left-36 sm:after:left-28 after:left-20">
                        <div className="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                          <span className="w-6 h-6 bg-accent rounded-full flex justify-center items-center mx-auto mb-1 text-white text-base font-bold lg:w-10 lg:h-10">
                            2
                          </span>
                          Preparing
                          <span className="text-gray-500 text-base font-normal text-center">
                            Now
                          </span>
                        </div>
                      </li>
                      <li className="flex w-full relative justify-center text-gray-500 text-base font-semibold">
                        <div className="block sm:whitespace-nowrap z-10 flex flex-col items-center">
                          <span className="w-6 h-6 text--600 border-2 bg-transparent border--600 rounded-full flex justify-center items-center mx-auto mb-1 text-sm lg:w-10 lg:h-10">
                            3
                          </span>
                          To Serve
                          <span className="text-gray-500 text-base font-normal text-center">
                            Estimated:{' '}
                            {new Date(Date.now() + 20 * 60 * 1000)
                              .toLocaleTimeString()
                              ?.split(':')
                              ?.slice(0, 2)
                              ?.join(':')}
                          </span>
                        </div>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {loading && <Loader progress={'Fetching order details...'} />}
          {/* {!loading && !data && <NotFound text="No Order" />} */}
        </div>
      </div>
    </>
  )
}

export default Order
