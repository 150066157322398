import Menu from '../Components/Menu'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function MenuPage() {
  return (
    <div className="App">
      <Header />
      <Menu />
      <Footer />
    </div>
  )
}

export default MenuPage
