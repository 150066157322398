import React from "react";
import PickMeals from "../Assets/order-food.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/trolley.png";

const Work = () => {
  const workInfoData = [
    {
      image: PickMeals,
      title: "Order On Table",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et sagittis.",
    },
    {
      image: ChooseMeals,
      title: "Online Menu",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et ",
    },
    {
      image: DeliveryMeals,
      title: "Get Served",
      text: "Lorem ipsum dolor sit amet consectetur. Maecenas orci et lorem ipsum.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Work</p>
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et
          elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="info-boxes-img-container h-40 flex items-center">
              <img src={data.image} alt="" className="h-20 " />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Work;
