import Order from '../Components/Order'
import Footer from '../Components/Footer'
import Header from '../Components/Header'

function OrderPage() {
  return (
    <div className="App">
      <Header />
      <Order />
      <Footer />
    </div>
  )
}

export default OrderPage
