import Home from '../Components/Home'
import About from '../Components/About'
import Work from '../Components/Work'
import Testimonial from '../Components/Testimonial'
import Contact from '../Components/Contact'
import Header from '../Components/Header'
import Footer from '../Components/Footer'

function HomePage() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <Work />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  )
}

export default HomePage
