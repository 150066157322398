import { motion } from 'framer-motion'
import { FaCartPlus, FaPlus } from 'react-icons/fa'
import sbImg from '../Assets/sb.png'
import { addToCart } from '../utils/functions'
import { useStateValue } from '../context/StateProvider'

const FoodItem = ({ item }) => {
  const [{ cartItems }, dispatch] = useStateValue()
  const { id, name, price, discount, category, imageUrl, description } = item
  const add = (e) => {
    // console.log('add to cart', item)
    // if (!item?.options) {
    //   e.preventDefault()
    //   addToCart(item, {}, 1, cartItems, dispatch, null)
    // }
  }
  return (
    <motion.div
      whileTap={{ rotate: [0, -1, 1, -1, 0] }}
      className={`grid grid-cols-5 gap-2 sm:block h-auto bg-white rounded-lg p-2 px-3 pr-0 pb-0 mt-4 backdrop-blur-lg hover:drop-shadow-sm cursor-pointer`}
    >
      <div className="col-span-2 flex-grow flex items-center justify-between">
        <motion.img
          whileHover={{ scale: 1.2 }}
          whileTap={{ scale: 1.1 }}
          className="sm:w-48 sm:h-40 -mt-8 object-contain cursor-pointer"
          alt={description}
          src={imageUrl}
        />
      </div>
      <div className="col-span-3 flex-growx sm:w-full flex items-end justify-end flex-col">
        <div className="pt-2 md:pt-0 pr-2 md:pb-2 w-full">
          <p className="text-textColor font-semi-bold text-lg line-clamp-1 sm:line-clamp-2">
            {name}
          </p>
          <p className="mt-1 text-sm text-gray-500 line-clamp-2 mb-2">
            {description}{' '}
          </p>
        </div>

        <div className="flex gap-4 items-center rounded-lg flex-row-reverse overflow-hidden">
          <button
            onClick={add}
            className="h-12 w-12 md:h-16 md:w-16 flex items-center justify-center rounded-full bg-gray-300 border-4 border-gray-200 text-white mr-[-12.5px] mb-[-12.5px]"
          >
            <FaPlus />
          </button>
          <div className="flex items-center justify-between gap-4">
            {discount > 0 && (
              <p className="text-base text-gray-400 font-semibold line-through">
                <span className="text-sm">৳</span> {discount}
              </p>
            )}
            <p className="text-base text-headingColor font-semibold">
              <span className="text-sm text-accent">৳</span> {price}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default FoodItem
