import API from '../config/config'

const PATH = '/order/v1'

const getAll = (merchantId, categoryId, key) => {
  return API.get(`${PATH}`, {
    categoryId,
    key,
  })
    .then((res) => {
      return res.data?.orders
    })
    .catch((error) => {
      console.error(error)
      return []
    })
}

const getOne = (id) => {
  return API.get(`${PATH}/details/${id}`)
    .then((res) => {
      return res.data?.orderDetails
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

const addOne = (data) => {
  return API.post(`${PATH}`, data)
    .then((res) => {
      return res.data?.order
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

export { getAll, getOne, addOne }
