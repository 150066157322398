import React from 'react'

const CardForm = ({ userNameRef, mobileRef }) => {
  return (
    <div className="w-full rounded-lg flex flex-col p-4 px-6 my-12">
      <div className="w-full flex flex-col mb-4">
        <label htmlFor="name" className="font-bold text-sm mb-1 text-gray-100">
          Name
        </label>
        <input
          type="text"
          id="name"
          className="w-full px-3 py-2 mb-1 border-2 text-white border-gray-500 rounded-md focus:outline-none focus:border-accent-2 focus:text-accent-2 bg-cartItem transition-colors"
          placeholder="Type name..."
          autoComplete="off"
          ref={userNameRef}
        />
      </div>
      <div className="w-full flex flex-col mb-2">
        <label
          htmlFor="number"
          className="font-bold text-sm mb-1 text-gray-100"
        >
          Mobile Number
        </label>
        <input
          type="text"
          id="number"
          className="w-full px-3 py-2 mb-1 border-2 text-white border-gray-500 rounded-md focus:outline-none focus:border-accent-2 focus:text-accent-2 bg-cartItem transition-colors"
          placeholder="01..."
          autoComplete="off"
          ref={mobileRef}
        />
      </div>
    </div>
  )
}

export default CardForm
