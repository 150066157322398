import { MdOutlineFastfood } from 'react-icons/md'

const FilterButton = ({ category, filter, setFilter }) => {
  return (
    <a
      href={`#${category.name?.split(' ').join('-')}`}
      whileTap={{ scale: 1.1 }}
      className={`group ${
        category.urlParam === filter
          ? 'hover:bg-btnOverlay bg-cartNumBg'
          : 'bg-btnOverlay hover:bg-cartNumBg'
      } min-w-[6rem] px-4 h-24 cursor-pointer rounded-lg drop-shadow-xl flex flex-col gap-1 items-center justify-center duration-150 transition-all  ease-out`}
    >
      <div
        className={`w-10 h-10 rounded-full ${
          category.urlParam === filter
            ? 'group-hover:bg-cartNumBg bg-btnOverlay'
            : 'bg-cartNumBg group-hover:bg-btnOverlay'
        }  flex items-center justify-center`}
      >
        <span
          className={`${
            category.urlParam === filter
              ? 'text-textColor group-hover:text-btnOverlay'
              : 'group-hover:text-textColor text-btnOverlay'
          } text-lg`}
        >
          {category.icon || <MdOutlineFastfood />}
        </span>
      </div>
      <p
        className={`text-base text-nowrap overflow-x-auto ${
          category.urlParam === filter
            ? 'group-hover:text-textColor text-white'
            : 'text-textColor group-hover:text-white'
        } `}
      >
        {category.name
          ?.split(' ')
          .filter((_, i) => i < 2)
          .join(' ')}
      </p>
    </a>
  )
}

export default FilterButton
