import React from 'react'
import NotFoundImg from '../Assets/empty3.png'

const NotFound = ({ text }) => {
  return (
    <div className="w-full p-5 flex flex-col h-80 mb-32 my-24 items-center gap-4 justify-center">
      <img className="h-80" src={NotFoundImg} alt="empty cart" />
      <p className="text-textColor  font-semibold">{text}</p>
    </div>
  )
}

export default NotFound
