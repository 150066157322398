import React from 'react'
import BannerBackground from '../Assets/home-banner-background.png'
import BannerImage from '../Assets/dish1.webp'
import { Link } from 'react-router-dom'
import { FiArrowRight } from 'react-icons/fi'

const Home = () => {
  return (
    <div className="home-container">
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">Enjoy Fresh Meals at Your Table</h1>
          <p className="primary-text">
            Healthy switcher chefs do all the prep work, like peeding, chopping
            & marinating, so you can cook a fresh food.
          </p>
          <div className="mt-4">
            <Link to="/menu" className="secondary-button">
              Order Now <FiArrowRight />{' '}
            </Link>
          </div>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  )
}

export default Home
