import {
  fetchSessionUser,
  fetchSessionUserMode,
  fetchSessionShopId,
  fetchSessionTableNumber,
} from '../utils/fetchSessionData'

const sessionUser = fetchSessionUser()
const sessionUserMode = fetchSessionUserMode()
export const initialState = {
  user: sessionUser,
  showCart: false,
  showContactForm: false,
  cartItems: [],
  cartTotal: 0,
  adminMode: sessionUserMode,
  users: [],
  paymentMethod: 'mobile_money',
  checkoutData: {},
  shopId: fetchSessionShopId(),
  tableNumber: fetchSessionTableNumber(),
}
