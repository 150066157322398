import React from 'react'
import Logo from '../Assets/Logo.svg'
import { BsTwitter } from 'react-icons/bs'
import { SiLinkedin } from 'react-icons/si'
import { BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'

const Footer = () => {
  return (
    <div className="footer-wrapper justify-between gap-0 lg:gap-20">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>
        <p className="mt-10 text-color-dark text-sm">&copy; Foo | 2024</p>
      </div>
      <div className="footer-section-two grid grid-cols-2 md:grid-cols-3 md:gap-20 lg:mt-8">
        <div className="footer-section-columns col-span-1">
          <span>Catalog</span>
          <span>Help</span>
          <span>Testimonials</span>
          <span>Work</span>
        </div>
        <div className="footer-section-columns col-span-1">
          <span>XXX-XXXX-XXXX</span>
          <span>order@food.com</span>
          <span>complain@food.com</span>
          <span>contact@food.com</span>
        </div>
        <div className="footer-section-columns col-span-2 lg:col-span-1">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  )
}

export default Footer
