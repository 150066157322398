import API from '../config/config'

const PATH = '/product/v1'

const getAll = (merchantId, categoryId, key) => {
  return API.get(`${PATH}/merchant/${merchantId}`, {
    categoryId,
    key,
  })
    .then((res) => {
      return res.data?.products
    })
    .catch((error) => {
      console.error(error)
      return []
    })
}

const getOne = (id) => {
  return API.get(`${PATH}/details/${id}`)
    .then((res) => {
      return res.data?.productDetails
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}

export { getAll, getOne }
